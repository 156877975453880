.modal-show-common {
  &.content-transparent {
    .ant-modal-content {
      padding: 0;
      border-radius: 10px;
      background-color: transparent !important;
    }
  }

  &.no-box-shadow {
    .ant-modal-content {
      box-shadow: none;
    }
  }
}
