@import '../../style/theme/index.less';

.tdSearchSelectPopupCss {
  .ant-select-item-option-selected {
    background-color: #fff !important;
    color: @primary-color !important;
  }

  .ant .ant-select-item-option-active {
    background-color: #f3f5ff !important;
  }
}

.tdSearchSelectCss {
  .ant-select-arrow {
    color: #7b7f97;
    font-size: 14px;
  }

  .ant-select-selection-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline;

    .ant-select-selection-overflow-item {
      display: inline;
    }

    .ant-select-selection-overflow-item-suffix {
      display: none;
    }
  }
}
