@import '../../style/theme/index.less';

.td-button {
  border-radius: 4px;
}

.td-disabled-button {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 25%);
  background-color: rgba(0, 0, 0, 4%);
  box-shadow: none;

  &:hover,
  &:active {
    cursor: not-allowed;
    border-color: #d9d9d9 !important;
    color: rgba(0, 0, 0, 25%) !important;
    background-color: rgba(0, 0, 0, 4%);
    box-shadow: none;
  }
}

.td-countdown-button {
  &:hover {
    color: #999 !important;
  }

  &:focus {
    color: #999 !important;
  }
}