.career-components-tagmodal .ant-select-selection {
  background: #fff !important;
  cursor: pointer;
}
.career-components-tagmodal .ant-select-multiple .ant-select-selection-item {
  margin-right: 12px;
}
.career-components-tagmodal--JobKeywords .ant-modal-content {
  overflow: hidden;
  border-radius: 10px;
}
.career-components-tagmodal--JobKeywords .title {
  height: 44px;
  color: #262626;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
}
.career-components-tagmodal--JobKeywords .listTitle {
  margin-bottom: 16px;
  color: #262626;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
}
.career-components-tagmodal--JobKeywords .card {
  position: relative;
  box-sizing: border-box;
  height: 40px;
  margin-top: 0;
  margin-right: 16px;
  margin-bottom: 16px;
  padding-top: 0;
  padding-right: 24px;
  padding-left: 16px;
  overflow: hidden;
  color: #262626;
  font-size: 14px;
  line-height: 40px;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
  background-color: #f1f2f2;
  border: 1px solid #f1f2f2;
  border-radius: 4px;
  cursor: pointer;
}
.career-components-tagmodal--JobKeywords .card .anticon-close {
  position: absolute;
  top: 14px;
  right: 8px;
}
.career-components-tagmodal--JobKeywords .selected {
  box-sizing: border-box;
  color: #4256DC;
  background-color: #fff;
  border: 1px solid #4256DC;
}
.career-components-tagmodal--JobKeywords .selected .anticon-close {
  color: #4256DC !important;
}
.career-components-tagmodal--JobKeywords .cardList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}
.career-components-tagmodal--JobKeywords .cardList .create {
  width: 122px;
  height: 40px;
  background: #fff;
  border: 1px solid #262626;
  border-radius: 5px;
}
.career-components-tagmodal--JobKeywords .cardList .ant-input {
  width: 400px;
}
.career-components-tagmodal--JobKeywords .cardList .ant-input-affix-wrapper {
  width: 400px !important;
  font-size: 14px;
  border-color: #262626;
  border-radius: 5px;
}
.career-components-tagmodal--JobKeywords .cardList .sure {
  color: #4256DC;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -2px;
  border: none;
  box-shadow: none;
}
.career-components-tagmodal--JobKeywords .cardList .cancel {
  color: #9aa3b7;
  font-weight: 500;
  font-size: 14px;
  border: none;
  box-shadow: none;
}
.career-components-tagmodal--JobKeywords .cardList .disable {
  color: #ced2d9;
  cursor: no-drop;
  pointer-events: none;
}
.career-components-tagmodal--JobKeywords .ant-modal-footer {
  padding-bottom: 8px;
}
.career-components-tagmodal--JobKeywords .footers {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 14px 8px;
  padding-bottom: 0;
}
.career-components-tagmodal--JobKeywords .footers .selectedCardList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 570px;
}
.career-components-tagmodal--JobKeywords .footers .titleText {
  color: #262626;
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
}
.career-components-tagmodal--JobKeywords .footers .submit {
  margin-bottom: 16px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  background: #4256DC;
  border-color: #4256DC;
  border-radius: 4px;
}
.career-components-tagmodal--JobKeywords .airecommend {
  color: #fff;
  font-weight: 400;
  font-size: 12px;
}
.career-components-tagmodal--JobKeywords .scrollcontent {
  height: 338px;
  overflow-y: auto;
}
.career-components-tagmodal--JobKeywords .dividerCss .ant-divider-inner-text {
  padding: 0;
}
.career-components-tagmodal--JobKeywords .moreCss {
  padding: 0 16px;
  font-size: 12px;
}
.career-components-tagmodal--JobKeywords .moreCss:hover {
  color: #262626;
  border-color: #d9d9d9;
}
.career-components-tagmodal--JobKeywords .moreCss:focus {
  color: #262626;
  border-color: #d9d9d9;
}
.td-autoscroller {
  overflow: hidden;
  width: 100%;
  height: 80px;
}
.td-autoscroller-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.td-autoscroller-container > * {
  flex: 0 0 auto;
}
.td-autoscroller-container--X {
  flex-direction: row;
}
.td-autoscroller-container--Y {
  flex-direction: column;
}
.td-Avatar {
  display: inline-block;
  position: relative;
}
.td-Avatar .pixelated img {
  filter: blur(4px);
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.td-Avatar__pendantImg {
  position: absolute;
  z-index: 4;
  width: 130%;
  top: -23%;
  left: -16%;
}
.td-Avatar__noticeMask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.65);
  color: #fff;
  transition: all 0.2s ease-out;
  opacity: 0;
  cursor: pointer;
}
.td-Avatar__noticeMask:hover {
  opacity: 1;
}
.td-Avatar__genderIcon {
  position: absolute;
  top: 11%;
  right: 11%;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
  z-index: 3;
  width: 28%;
  height: 28%;
}
.modal-show-empty .ant-modal-body {
  padding: 0;
}
.modal-show-empty .ant-modal-content {
  border-radius: 5px;
  padding: 0;
}
.modal-show-empty .ant-modal-confirm-content {
  margin: 0;
}
.modal-show-empty .ant-modal-confirm-btns {
  margin: 0;
}
.modal-show-empty-content {
  position: relative;
}
.modal-show-empty-content-close {
  position: absolute;
  font-size: 30px;
  right: -40px;
  top: 0;
  color: #fffc;
  cursor: pointer;
}
.modal-show-empty-content-close.light {
  color: #fff;
}
.modal-show-empty-content-close-inside {
  position: absolute;
  font-size: 18px;
  right: 20px;
  top: 20px;
  color: #7a7f99;
  cursor: pointer;
}
.modal-show-empty-content-close-inside.light {
  color: #fff;
}
.modal-show-empty .td-Avatar__preview {
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-show-empty .td-Avatar__preview img {
  max-width: 400px;
  max-height: 400px;
}
.td-button {
  border-radius: 4px;
}
.td-disabled-button {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}
.td-disabled-button:hover,
.td-disabled-button:active {
  cursor: not-allowed;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}
.td-countdown-button:hover {
  color: #999 !important;
}
.td-countdown-button:focus {
  color: #999 !important;
}
.td-cascaderModal__modal .ant-modal-header {
  padding: 16px 24px;
  color: #5a5a5a;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 10px 10px 0 0;
  margin-bottom: 0;
}
.td-cascaderModal__modal .ant-modal-content {
  border-radius: 10px;
  overflow: hidden;
  padding: 0;
}
.td-cascaderModal__modal .ant-modal-header {
  border-radius: 10px 10px 0 0;
}
.td-cascaderModal__modal .ant-modal-body {
  padding: 0;
}
.td-cascaderModal__modal .ant-modal-footer {
  margin-top: 0;
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
}
.td-cascaderModal__top {
  position: relative;
  height: 32px;
}
.td-cascaderModal__title {
  height: 32px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #262626;
  line-height: 32px;
}
.td-cascaderModal__list {
  display: flex;
}
.td-cascaderModal__list .td-tag-button.default.td-tag-button__selected > .ant-btn {
  color: #262626;
  background-color: #f1f2f2;
  border-radius: 5px;
  font-weight: 600;
}
.td-cascaderModal__list .td-tag-button > .ant-btn:hover,
.td-cascaderModal__list .td-tag-button > .ant-btn:active {
  color: #262626;
  background-color: #f1f2f2;
  border-radius: 5px;
  font-weight: 600;
}
.td-cascaderModal__chunkList {
  display: flex;
  align-content: start;
  height: 435px;
  padding: 16px;
  margin: 0px;
  border-right: 1px solid #ebebeb;
  list-style-type: none;
  box-sizing: border-box;
  overflow-y: auto;
}
.td-cascaderModal__chunkList li {
  height: 40px;
  margin: 0px 0px 16px;
  font-size: 14px;
  color: #262626;
  line-height: 40px;
}
.td-cascaderModal__chunkList:last-child {
  border: none;
}
.td-cascaderModal__chunkList:last-child .td-tag-button.default.td-tag-button__selected > .ant-btn {
  background-color: transparent;
  border: 1px solid #4256DC;
  color: #4256DC;
  font-weight: 500;
}
.td-cascaderModal__chunkList:last-child .td-tag-button > .ant-btn:hover,
.td-cascaderModal__chunkList:last-child .td-tag-button > .ant-btn:active {
  color: #262626;
  background-color: transparent;
  border-radius: 5px;
  font-weight: 500;
}
.td-cascaderModal__searchInput {
  height: 40px;
  position: absolute;
  top: -2px;
  left: 235px;
}
.td-cascaderModal__searchInput > i {
  font-size: 14px;
  color: #A6A6A6;
  position: absolute;
  left: 12px;
  top: 10px;
  z-index: 6;
}
.td-cascaderModal__searchInput .ant-select:not(.ant-select-customize-input) {
  width: 320px;
  height: 40px;
  position: absolute;
}
.td-cascaderModal__searchInput .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  width: 320px;
  height: 40px;
  border-radius: 5px;
  padding-left: 30px;
  font-size: 14px;
  font-weight: 400;
  color: #262626;
  border: none;
  background-color: #F1F2F3;
  align-items: center;
}
.td-cascaderModal__searchInput .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search {
  width: calc(100% - 16px) !important;
  padding-left: 30px;
  display: inline-table;
  top: 5px;
}
.td-cascaderModal__searchInput .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  padding-left: 12px;
}
.td-cascaderModal__searchInput .ant-select:not(.ant-select-customize-input) .ant-select-clear {
  background: initial;
}
.td-cascaderModal__searchInput .ant-select-open .ant-select-selector,
.td-cascaderModal__searchInput .ant-select-focused .ant-select-selector {
  background: #fff !important;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15) !important;
}
.td-cascaderModal__searchInput__notFount {
  height: 40px;
  padding: 0;
  padding-left: 24px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #262626;
  line-height: 22px;
}
.td-cascaderModal__searchInput__notFount > .anticon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  color: #4256DC;
}
.td-cascaderModal-menu-item-keyword {
  color: #4256DC;
}
.td-cascaderModal-footer {
  width: 100%;
  padding: 14px 8px;
  max-height: 138px;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.td-cascaderModal-footer__label {
  line-height: 40px;
  font-size: 16px;
  font-weight: 600;
  color: #262626;
}
.td-cascaderModal-footer__tags {
  width: 570px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.td-cascaderModal-footer .submit {
  min-width: 89px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background: #436ef3;
  border-color: #436ef3;
  border-radius: 4px;
}
.td-cascaderModal-footer .submit[disabled] {
  background: #f1f2f2;
  border-color: #f1f2f2;
  color: #ced2d9;
}
.ant-picker-today-btn {
  text-align: center;
  cursor: pointer;
}
.ant-picker {
  height: 36px;
  background: #FFFFFF;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  border: 1px solid #E2E4EE;
}
.ant-picker .ant-picker-input > input::placeholder {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #C7CBDE;
}
.td-button {
  border-radius: 4px;
}
.block-content-wrap {
  width: var(--width);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: var(--maxrows);
  text-overflow: ellipsis;
  white-space: normal;
}
.expand-btn {
  color: #4256DC;
}
.td-image-group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  position: absolute;
  left: 0;
  max-width: 100%;
}
.td-image-group__wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.td-image-group .ant-image {
  flex-shrink: 0;
}
.td-image-group__animate {
  -webkit-animation: imgScroll 30s linear infinite;
  -moz-animation: imgScroll 30s linear infinite;
}
.td-image-group__pauseOnHover:hover,
.td-image-group__paused {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
}
.ant-image-preview-switch-left {
  left: 46%;
}
.ant-image-preview-switch-right {
  right: 46%;
}
.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  top: 81%;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: -4px -6px 7px 0px rgba(166, 191, 255, 0.06), 3px 4px 7px 2px rgba(156, 179, 238, 0.07), -4px 9px 7px 0px rgba(152, 170, 206, 0.08);
}
@keyframes imgScroll {
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
}
.customInputStyle {
  color: #141933;
  border-radius: 6px;
  background: #FFFFFF;
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #E2E4EE;
}
.customInputStyle::placeholder {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #C7CBDE;
  line-height: 22px;
}
input[class*="td-input"] {
  color: #141933;
  border-radius: 6px;
  background: #FFFFFF;
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #E2E4EE;
  height: 36px;
}
input[class*="td-input"]::placeholder {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #C7CBDE;
  line-height: 22px;
}
input[class*="td-input"] .ant-input {
  color: #141933;
  border-radius: 6px;
  background: #FFFFFF;
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #E2E4EE;
  height: auto;
}
input[class*="td-input"] .ant-input::placeholder {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #C7CBDE;
  line-height: 22px;
}
.td-input__countDownInput {
  display: flex;
  position: relative;
  align-items: center;
}
.td-input__countDownInput .ant-input {
  height: 40px;
  background: #f7f8fa;
  border-radius: 5px;
  opacity: 1;
  border: 1px solid #b6bbd7;
}
.td-input__countDownInput button {
  right: 10px;
  position: absolute;
  z-index: 1;
}
div[class*="td-input-textarea"] .ant-input {
  color: #141933;
  border-radius: 6px;
  background: #FFFFFF;
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #E2E4EE;
  padding: 5px 12px;
}
div[class*="td-input-textarea"] .ant-input::placeholder {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #C7CBDE;
  line-height: 22px;
}
div[class*="td-input-textarea"]::after {
  color: #9aa3b7;
}
.modal-show-common.content-transparent .ant-modal-content {
  padding: 0;
  border-radius: 10px;
  background-color: transparent !important;
}
.modal-show-common.no-box-shadow .ant-modal-content {
  box-shadow: none;
}
.td-range-select__wrap {
  width: 100%;
  display: flex;
  align-items: center;
  min-width: 496px;
  justify-content: space-between;
}
.td-range-select .ant-select {
  display: inline;
}
.td-range-select__jointMark {
  color: #141933;
}
.td-range-select__rightWord {
  font-size: 14px;
  margin-top: -2px;
  display: block;
}
.tdSearchSelectPopupCss .ant-select-item-option-selected {
  background-color: #fff !important;
  color: #4256DC !important;
}
.tdSearchSelectPopupCss .ant .ant-select-item-option-active {
  background-color: #f3f5ff !important;
}
.tdSearchSelectCss .ant-select-arrow {
  color: #7b7f97;
  font-size: 14px;
}
.tdSearchSelectCss .ant-select-selection-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline;
}
.tdSearchSelectCss .ant-select-selection-overflow .ant-select-selection-overflow-item {
  display: inline;
}
.tdSearchSelectCss .ant-select-selection-overflow .ant-select-selection-overflow-item-suffix {
  display: none;
}
.td-tag-button {
  border-radius: 4px;
}
.td-tag-button .ant-btn {
  box-shadow: none;
  border-radius: 4px;
  min-height: 40px;
  height: auto;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.td-tag-button .ant-btn span {
  white-space: pre-wrap;
}
.td-tag-button .ant-btn-sm {
  font-size: 12px;
  min-height: 22px;
  padding: 1px 4px;
  transform: scale(0.83);
}
.td-tag-button .ant-btn:hover,
.td-tag-button .ant-btn:active,
.td-tag-button .ant-btn-background-ghost {
  background-color: transparent;
}
.td-tag-button .ant-btn.noHover.ant-btn-link:hover,
.td-tag-button .ant-btn.noHover.ant-btn-link:active {
  border-color: transparent !important;
  background-color: transparent !important;
}
.td-tag-button .ant-btn.noHover.ant-btn-background-ghost:hover,
.td-tag-button .ant-btn.noHover.ant-btn-dashed:hover,
.td-tag-button .ant-btn.noHover.ant-btn-background-ghost:active,
.td-tag-button .ant-btn.noHover.ant-btn-dashed:active {
  background-color: transparent !important;
}
.td-tag-button__close-icon,
.td-tag-button__icon {
  margin-left: auto;
  padding-left: 16px;
  font-size: 13px;
}
.td-tag-button--disabled .ant-btn {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none;
}
.td-tag-button--disabled .ant-btn:hover,
.td-tag-button--disabled .ant-btn:active {
  border-color: transparent !important;
}
.td-tag-button .noBordered {
  border: none;
}
.td-tag-button__closed {
  display: none;
}
.td-tag-button__ellipsis .ant-btn > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.td-tag-button .noHover {
  cursor: text !important;
}
.warm .ant-btn,
.warm .ant-btn.ant-btn-background-ghost {
  border-color: #ef953c;
  color: #ef953c;
  background-color: rgba(248, 199, 149, 0.4);
}
.warm .noHover:hover,
.warm .noHover:active {
  border-color: #ef953c !important;
  color: #ef953c !important;
  background-color: rgba(248, 199, 149, 0.4) !important;
}
.warm .ant-btn-link {
  border-color: transparent;
  background-color: transparent;
}
.warm .ant-btn.ant-btn-background-ghost,
.warm .ant-btn.ant-btn-dashed {
  background-color: transparent;
}
.cold .ant-btn,
.cold .ant-btn.ant-btn-background-ghost {
  border-color: #c0c8f0;
  color: #2d75e6;
  background-color: #eaf1fc;
}
.cold .noHover:hover,
.cold .noHover:active {
  border-color: #c0c8f0 !important;
  color: #2d75e6 !important;
  background-color: #eaf1fc !important;
}
.cold .ant-btn-link {
  border-color: transparent;
  background-color: transparent;
}
.cold .ant-btn.ant-btn-background-ghost,
.cold .ant-btn.ant-btn-dashed {
  background-color: transparent;
}
.hope .ant-btn,
.hope .ant-btn.ant-btn-background-ghost {
  border-color: rgba(160, 183, 127, 0.53);
  color: #80a946;
  background-color: rgba(160, 183, 127, 0.2);
}
.hope .noHover:hover,
.hope .noHover:active {
  border-color: rgba(160, 183, 127, 0.53) !important;
  color: #80a946 !important;
  background-color: rgba(160, 183, 127, 0.2) !important;
}
.hope .ant-btn-link {
  border-color: transparent;
  background-color: transparent;
}
.hope .ant-btn.ant-btn-background-ghost,
.hope .ant-btn.ant-btn-dashed {
  background-color: transparent;
}
.hurry .ant-btn,
.hurry .ant-btn.ant-btn-background-ghost {
  border-color: #cf303a;
  color: #cf303a;
  background-color: rgba(207, 48, 58, 0.2);
}
.hurry .noHover:hover,
.hurry .noHover:active {
  border-color: #cf303a !important;
  color: #cf303a !important;
  background-color: rgba(207, 48, 58, 0.2) !important;
}
.hurry .ant-btn-link {
  border-color: transparent;
  background-color: transparent;
}
.hurry .ant-btn.ant-btn-background-ghost,
.hurry .ant-btn.ant-btn-dashed {
  background-color: transparent;
}
.official .ant-btn,
.official .ant-btn.ant-btn-background-ghost {
  border-color: #e2e4ee;
  color: #7a7f99;
  background-color: #f3f5ff;
}
.official .noHover:hover,
.official .noHover:active {
  border-color: #e2e4ee !important;
  color: #7a7f99 !important;
  background-color: #f3f5ff !important;
}
.official .ant-btn-link {
  border-color: transparent;
  background-color: transparent;
}
.official .ant-btn.ant-btn-background-ghost,
.official .ant-btn.ant-btn-dashed {
  background-color: transparent;
}
.default .ant-btn,
.default .ant-btn.ant-btn-background-ghost {
  border-color: #e2e4ee;
  color: #262626;
  background-color: #F1F2F2;
}
.default .noHover:hover,
.default .noHover:active {
  border-color: #e2e4ee !important;
  color: #262626 !important;
  background-color: #F1F2F2 !important;
}
.default .ant-btn-link {
  border-color: transparent;
  background-color: transparent;
}
.default .ant-btn.ant-btn-background-ghost,
.default .ant-btn.ant-btn-dashed {
  background-color: transparent;
}
.td-tag-button__selected .ant-btn,
.td-tag-button__selected .ant-btn.ant-btn-background-ghost {
  background: #FFFFFF;
  color: #4256DC;
  border-color: #4256DC;
}
.td-tag-select__title {
  line-height: 30px;
  font-size: 14px;
  font-weight: 500;
  color: #141933;
  margin-bottom: 8px;
}
.td-tag-select__desc {
  font-size: 12px;
  color: #474c66;
}
.td-tag-select__list {
  display: flex;
  flex-wrap: wrap;
}
.td-tag-select__list .td-tag-button {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.td-duringDatePicker {
  display: flex;
  align-items: center;
}
.td-duringDatePicker .split {
  padding: 0 12px;
}
.td-cascaderModalSelect__input {
  width: 300px;
}
.td-cascaderModalSelect__input .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #E2E4EE;
  color: #141933;
}
.td-cascaderModalSelect__input .ant-select:not(.ant-select-multiple) .ant-select-selection-item {
  line-height: 34px;
}
.td-cascaderModalSelect__input .ant-select {
  width: 100%;
  min-height: 36px;
}
.td-cascaderModalSelect__input .ant-select .ant-select-selector {
  min-height: 36px;
}
.td-cascaderModalSelect__input .ant-select .ant-select-selector .ant-select-selection-search input {
  height: 100%;
}
.td-cascaderModalSelect__input .ant-select .ant-select-selector .ant-select-selection-item {
  color: #141933;
}
.td-cascaderModalSelect__input .ant-select .ant-select-selector .ant-select-selection-placeholder {
  line-height: 34px;
  color: #C7CBDE;
  font-weight: 400;
}
.td-share-modal .rel {
  position: relative;
}
.td-share-modal .strong {
  font-weight: bold;
}
.td-share-modal .f18 {
  font-size: 18px;
}
.td-share-modal .f12 {
  font-size: 12px;
}
.td-share-modal .ant-modal-content {
  background: #ffffff;
  border-radius: 4px;
}
.td-share-modal .ant-modal-header {
  background: #f6f7fa;
  border-bottom-width: 0;
}
.td-share-modal .ant-modal-footer {
  padding: 0;
}
.td-share-modal-canvas {
  width: 320px;
  position: relative;
  margin: 0 auto;
}
.td-share-modal-canvas .td-share-modal-canvas__scrolls {
  width: 310px;
  height: 540px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  scrollbar-width: none;
  /* firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}
.td-share-modal-canvas .td-share-modal-canvas__scrolls::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
.td-share-modal-canvas .td-share-modal-canvas__wrapper {
  width: 310px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-size: cover;
}
.td-share-modal-canvas .td-share-modal-canvas__content {
  position: relative;
  height: 100%;
}
.td-share-modal-canvas .td-share-modal-canvas__content .logo {
  width: 83px;
  height: 19px;
  position: absolute;
  top: 29px;
  right: 13px;
}
.td-share-modal-canvas .td-share-modal-canvas__content .date {
  width: 30px;
  position: absolute;
  top: 30px;
  left: 14px;
  color: #fff;
  line-height: 16px;
}
.td-share-modal-canvas .td-share-modal-canvas__content .watermark {
  width: 256px;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  font-family: HYYakuHei-HEW, HYYakuHei;
  font-weight: 600;
  white-space: pre-wrap;
}
.td-share-modal-tipWrapper {
  width: 185px;
  height: 80px;
  background: #40405b;
  position: absolute;
  cursor: default;
}
.td-share-modal-tipWrapper::before {
  width: 6px;
  position: absolute;
  left: -16px;
  top: 0;
  bottom: 0;
  height: 8px;
  margin: auto 0;
  content: '';
  border: 6px solid #40405b;
  border-top-color: transparent;
  border-right-color: #40405b;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-width: 10px;
  border-top-width: 6px;
}
.td-share-modal-tipWrapper__content {
  width: 144px;
  height: 40px;
  margin: 23px 0 0 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.td-share-modal-tipWrapper__text {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}
.td-share-modal-tipWrapper__closeIcon {
  width: 10px;
  height: 10px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 8px;
  padding: 0.25em 0;
  color: #fff;
  font-size: 14px;
}
.td-share-modal__footer {
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e5e5e5;
}
.td-share-modal__footer__bgWrapper {
  border-radius: 4px;
  margin-right: 3px;
  position: relative;
}
.td-share-modal__footer__bgImg {
  width: 50px;
  height: 66px;
  border-radius: 4px;
  opacity: 0.6;
  cursor: pointer;
}
.td-share-modal__footer__bgImg--selected {
  width: 50px;
  height: 66px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  border: 2px solid #4773ff;
}
.td-share-modal__footer__bgImg--selected .checkImg {
  width: 29px;
  height: 5px;
  display: block;
  margin: 19px auto;
}
.td-login-modal .ant-modal-content {
  border-radius: 11px;
  padding: 0;
}
.td-login-modal .ant-modal-body {
  padding: 10% 8% 5% 8%;
}
.td-login-modal .ant-modal-body .ant-form {
  margin-top: 10%;
}
.td-login-modal .ant-modal-body .ant-form label {
  font-size: 12px;
}
.td-login-modal .ant-modal-body .td-input__countDownInput .ant-input {
  background-color: #fff;
}
.td-login-modal .ant-modal-body .td-input__countDownInput .ant-input::placeholder {
  color: #c2c6cc;
}
.td-login-modal .ant-modal-body .ant-input {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
}
.td-login-modal .ant-modal-body .ant-input::placeholder {
  color: #c2c6cc;
}
.td-login-modal .ant-modal-body .alcenter .ant-btn > span {
  color: #4256dc;
}
.td-login-modal__title {
  text-align: center;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141933;
}
.td-login-modal__subTitle {
  text-align: center;
  color: #c2c6cc;
}
.td-login-modal__protocol {
  color: #4256dc;
  cursor: pointer;
}
.td-login-modal__checkBox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #436ef3;
  border-color: #436ef3;
}
.td-login-modal__agreementRow .ant-form-item-control-input-content {
  height: 20px;
}
.td-login-modal__submitBtn {
  width: 100%;
  background: #4256dc;
  border-radius: 6px;
  font-size: 16px;
}
