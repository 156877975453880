.ant-picker-today-btn {
  text-align: center;
  cursor: pointer;
}

.ant-picker {
  height: 36px;
  background: #FFFFFF;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  border: 1px solid #E2E4EE;

  .ant-picker-input>input::placeholder {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #C7CBDE;
  }
}