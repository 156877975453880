@import '../../style/theme/index.less';

.td-range-select {
  &__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    min-width: 496px;
    justify-content: space-between;
  }

  .ant-select {
    display: inline;
  }

  &__jointMark {
    color: #141933;
  }

  &__rightWord {
    font-size: 14px;
    margin-top: -2px;
    display: block;
  }
}