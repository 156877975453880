@import '../../style/theme/index.less';

.td-button {
  border-radius: 4px;
}

.block-content-wrap {
  width: var(--width);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: var(--maxrows);
  text-overflow: ellipsis;
  white-space: normal;
}

.expand-btn {
  color: @primary-color;
}
