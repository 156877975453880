.td-cascaderModalSelect {
  &__input {
    width: 300px;

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid #E2E4EE;
      color: #141933;
    }

    .ant-select:not(.ant-select-multiple) {
      .ant-select-selection-item {
        line-height: 34px;
      }
    }

    .ant-select {
      width: 100%;
      min-height: 36px;

      .ant-select-selector {
        min-height: 36px;

        .ant-select-selection-search {
          input {
            height: 100%;
          }
        }

        .ant-select-selection-item {
          color: #141933;
        }

        .ant-select-selection-placeholder {
          line-height: 34px;
          color: #C7CBDE;
          font-weight: 400;
        }
      }
    }
  }
}