.td-login-modal {
  .ant-modal-content {
    border-radius: 11px;
    padding: 0;
  }

  .ant-modal-body {
    padding: 10% 8% 5% 8%;

    .ant-form {
      margin-top: 10%;

      label {
        font-size: 12px;
      }
    }

    .td-input__countDownInput {
      .ant-input {
        background-color: #fff;

        &::placeholder {
          color: #c2c6cc;
        }
      }
    }

    .ant-input {
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 14px;

      &::placeholder {
        color: #c2c6cc;
      }
    }

    .alcenter {
      .ant-btn>span {
        color: #4256dc;
      }
    }
  }

  &__title {
    text-align: center;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141933;
  }

  &__subTitle {
    text-align: center;
    color: #c2c6cc;
  }

  &__protocol {
    color: #4256dc;
    cursor: pointer;
  }

  &__checkBox {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #436ef3;
      border-color: #436ef3;
    }
  }

  &__agreementRow {
    .ant-form-item-control-input-content {
      height: 20px;
    }
  }

  &__submitBtn {
    width: 100%;
    background: #4256dc;
    border-radius: 6px;
    font-size: 16px;
  }
}